import { inject, Injectable, signal} from '@angular/core';
import {JwtHelperService} from "@auth0/angular-jwt";
import {Token} from "../models/token";

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  private permissions = signal<string[] | null>(null);
  private jwtHelper = inject(JwtHelperService);

  constructor() {
  }

  public async definePermissions(): Promise<void> {
    const token = await this.jwtHelper.decodeToken<Token>();
    if (!token || !token.permissions) {
      return;
    }
    this.permissions.set(token.permissions.split(','));
  }

  public resetPermissions(): void {
    this.permissions.set(null);
  }

  public has(permissionTag: string): boolean {
    return !!this.permissions()?.find(p => p === permissionTag);
  }
}
